.actmenu {
  position: absolute;
  width: 310px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(21px);
  z-index: 10000;
  animation: fadein 200ms ease-in-out;
  border: solid 1px #ccc9;
  border-radius: 8px;

  &[data-hide="true"] {
    display: none;
  }

  &[data-left="false"] .minimenu {
    left: unset;
    right: 98%;
  }
}

.minimenu {
  position: absolute;
  display: none;
  top: 0;
  left: 99%;
  min-width: 200px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  padding: 4px 0;
  border: solid 1px #ccc9;
  animation: fadein 600ms ease-in-out;
  border-radius: 8px;
}

.menuopt {
  height: 28px;
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 8px);
  padding: 4px 32px;
  font-size: 12px;
  cursor: default;
  margin: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;

  &:hover {
    background: rgba(0, 0, 0, 0.08);

    .minimenu {
      display: block;
    }
  }

  &[data-dsb="true"] {
    color: #aaa;
    font-weight: 400;
  }

  .uicon {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .prtclk {
    left: unset;
    right: 8px;
  }

  .dotIcon,
  .checkIcon {
    top: 8px;
    left: 8px;
    right: unset;
  }

  .checkIcon {
    top: 6px;
  }
}

.menuhr {
  position: relative;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc5;
  margin: 4px 0;
}
